import { Injectable } from '@angular/core';
import { MonthExpenses, iTrack } from '../common/interface';

@Injectable({
  providedIn: 'root',
})
export class TagsCacheService {
  // holds all the expenses for all the months
  expensesDetailsByMonth: MonthExpenses = {};
  budgetDetail: any;
  yearChartSummary: any = {};
  yearChartFilterTags: string = '';
  private trackerCache: { [key: string]: number } = {};

  constructor() {}

  getExpenseValue(expense: iTrack, tag: string) {
    const tagg = tag ? '-' + tag : '';
    const cacheId = 'getExpenseValue-' + expense.id + tagg;

    if (this.trackerCache[cacheId] != undefined) {
      return this.trackerCache[cacheId];
    }
    let returnValue = +expense.trackerValue!;

    //split tags tracker value
    if (expense.extraInfo && expense.extraInfo.splitTags) {
      const splitTagFound = expense.extraInfo.splitTags.filter(
        (t: { tag: string; splitValue: number }) => t.tag == tag,
      );
      if (splitTagFound.length > 0) {
        returnValue = +splitTagFound[0].splitValue;
      }
    }

    this.trackerCache[cacheId] = returnValue;
    return returnValue;
  }

  getExpenseWeight = (expense: iTrack) => {
    const cacheId = 'getExpenseWeight-' + expense.id;
    // return cached value
    if (this.trackerCache[cacheId] != undefined)
      return this.trackerCache[cacheId];

    let weightInLbs = 0;
    const hasWeight = expense.tags!.toLowerCase().indexOf('weight:') >= 0;
    if (hasWeight) {
      weightInLbs = 0;
      const weight = expense
        .tagsArray!.filter((tag) => tag.indexOf('weight:') >= 0)[0]
        .split(':')[1]
        .trim();

      if (weight.toLowerCase().indexOf('kg') >= 0) {
        weightInLbs =
          parseFloat(weight.replace('kg', '').replace('kgs', '')) * 2.2;
      } else {
        weightInLbs = parseFloat(weight.replace('lb', '').replace('lbs', ''));
      }

      return +weightInLbs;
    }

    this.trackerCache[cacheId] = weightInLbs;
    return weightInLbs;
  };

  getExpenseQuantity = (expense: iTrack) => {
    const cacheId = 'getExpenseQuantity-' + expense.id;

    // return cached value
    if (this.trackerCache[cacheId] != undefined)
      return this.trackerCache[cacheId];

    const hasQty = expense.tags!.toLowerCase().indexOf('qty:') >= 0;
    let qty = 1;

    if (hasQty) {
      qty = +expense
        .tagsArray!.filter((tag) => tag.indexOf('qty:') >= 0)[0]
        .split(':')[1]
        .trim();
    }

    this.trackerCache[cacheId] = qty;
    return qty;
  };

  filterOutTags = (
    filterTag: string[],
    expenses: { [key: string]: iTrack },
  ): { [key: string]: iTrack } => {
    // looping through list of filters and removing it from summary
    filterTag.forEach((ftag) => {
      for (const key in expenses) {
        // now try to find if tag is in the expense, if so delete the expense

        if (expenses[key].tags!.indexOf(ftag) >= 0) {
          delete expenses[key];
        }
      }
    });
    return expenses;
  };

  generateExpenseSummary(
    expenses: { [key: string]: iTrack },
    filterTag?: string[],
    tag?: string,
  ) {
    let filteredExpense = expenses;
    if (filterTag) {
      filteredExpense = this.filterOutTags(filterTag, expenses);
    }

    const summary = {
      expensesTotal: 0,
      length: 0,
      totalQty: 0,
      totalWeight: 0,
      expenses: {} as any,
    };

    for (const id in filteredExpense) {
      const expense = filteredExpense[id];
      summary.expensesTotal += tag
        ? this.getExpenseValue(expense, tag)
        : +expense.trackerValue!;
      // adding length + 1
      summary.length++;

      // working on quantity
      summary.totalQty += this.getExpenseQuantity(expense);

      // working on weight
      summary.totalWeight += this.getExpenseWeight(expense);

      summary.expenses[id] = expense;
    }
    return summary;
  }

  removeYearChartCacheByMonth(month: number, year: number) {
    delete this.yearChartSummary[month];
    this.deleteYearlySummaryByMonthToLocalStorage(month, year);
  }

  setYearChartSummary(month: number, data: any, year: number) {
    this.yearChartSummary[month] = data;
    this.saveYearlySummaryByMonthToLocalStorage(month, year, data);
  }

  private saveYearlySummaryByMonthToLocalStorage(
    month: number,
    year: number,
    summaryData: any,
  ) {
    const key =
      localStorage.getItem('userId') +
      `-yearly-chart-summary-month-${month}-year-${year}`;

    localStorage.setItem(key, JSON.stringify(summaryData));
  }

  private deleteYearlySummaryByMonthToLocalStorage(
    month: number,
    year: number,
  ) {
    const key =
      localStorage.getItem('userId') +
      `-yearly-chart-summary-month-${month}-year-${year}`;
    localStorage.removeItem(key);
  }

  getCleanObj(obj: any) {
    return JSON.parse(JSON.stringify(obj));
  }

  clearCache() {
    this.trackerCache = {};
  }
}
